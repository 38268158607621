///////////////////////////////////////
//// ABERTAY student/employer theme!
///////////////////////////////////////

// Romeo :: colours and banner/logo variables for the student/employer interface 
$romeo-primary:             #3caba4;
$romeo-secondary:           #b1045e;
$romeo-tertiary:            #44A59F;
$romeo-quaternary:          #3C4972;
$primary:                     $romeo-primary;
$secondary:                   $romeo-secondary;
$romeo-nav-items-hover:       $romeo-secondary;
$romeo-table-header-bg-color: $romeo-secondary;


$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":          $romeo-primary,
    "secondary":        $romeo-secondary,
    "tertiary":         $romeo-tertiary,
    "quaternary":       $romeo-quaternary,
    "success":          $success,
    "success-muted":    $success-muted,
    "info":             $info,
    "info-muted":       $info-muted,
    "warning":          $warning,
    "warning-muted":    $warning-muted,
    "danger":           $danger,
    "danger-muted":     $danger-muted,
    "light":            $light,
    "dark":             $dark,
    "default":          $default,
    "inferno-primary":  $inferno-primary,
    "inferno-secondary":$inferno-secondary
  ),
  $theme-colors
);
// Link colour for unauth pages
$link-color:            $romeo-primary;
$link-hover-color:      $romeo-secondary;